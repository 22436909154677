<template>
  <div class="wrap-Main">
    <div class="B-carddetail B-formDelay">
      <div class="box-S4 noPadding flex-between-center">
        <div class="box-S2">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="0"
            :nudge-top="0"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="hideMessage input-date"
                append-icon="mdi-calendar-range"
                v-model="picker"
                label="Select Date"
                readonly
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              type="month"
              @change="getData(picker,person)"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="box-S2">
         <!-- <v-combobox :items="itemsListPerson" label="Person" v-model="person"></v-combobox> -->
         <v-autocomplete
            
            :items="itemsListPerson"
            item-text="FullNameE"
            item-value="PersonID"
            v-model="person"
            label="Person"
            @change="getData(picker,person)"
          ></v-autocomplete>
        </div>
      </div>
      <div class="box-S4">
        <v-calendar
          ref="calendar"
          :now="today"
          v-model="startCalendar"
          type="month"
          :end="endCalendar"
          @click:date="Gotolistflight"
        >
          <template v-slot:day="{ date }">
            <template v-for="event in eventsMap[date]">
              <v-menu :key="event.title" v-model="event.open" full-width offset-x>
                <template v-slot:activator="{ on }">
                  <div v-if="!event.time" v-ripple  class="my-event" :style="{ 'background-color': '#ff0001' }" v-on="on" v-html="event.title" ba ></div>
                </template>
              </v-menu>
            </template>
          </template>
        </v-calendar>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientTiger from "../plugins/feathers-client-tiger";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate
} from "date-fns";
var today = new Date();
export default {
  data: () => ({
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    startCalendar: null,
    endCalendar: null,
    picker: {},
    datenow: new Date(),
    dateSelect: new Date().toISOString().substr(0, 7),
    menu: false,
    events: [],
    CaledarData: [],
    itemsPerson: {},
    itemsListPerson: [],
    person:''
  }),
  computed: {
    eventsMap() {
      const map = {};
      this.events.forEach(e => (map[e.date] = map[e.date] || []).push(e));
      return map;
    }
  },
  async mounted() {
    //init here
    this.picker = this.dateSelect;
   // this.getDataStart();
    this.SelectListPerson();
    //this.SelectListPosition();
  },

  methods: {
    open(event) {
      alert(event.title);
    },

    async getDataStart() {
      const q = {};

      var x = this.dateSelect.substr(0, 4);
      var y = this.dateSelect.substr(5, 2);
      //   var owner = this.$store.state.auth.user.Owner;
      // if(owner == "UOA"){
      //     owner = null
      // }else if (owner != "UOA"){

      //    owner = this.$store.state.auth.user.Owner;
      // }
      q.month = y;
      q.year = x;
      //q.owner = owner;

      // alert(JSON.stringify(q));
      // try {
      //   var res = await feathersClientUOA
      //     .service("flightselect")
      //     .find({ query: q });
      //   this.items = res[0].data[0];
      //   this.CaledarData = res[0].caledarData;
      // } catch (error) {
      //   console.log(error);
      //   //this.$toast.error("ไม่สามารถติดต่อ server ได้");
      // }
      // this.events = this.CaledarData;
      //alert(JSON.stringify(this.events2))
    },

    async SelectListPerson() {
      try {
       var res = await feathersClientTiger.service("person").find({});
        this.itemsListPerson = res.data;
        
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    // async SelectListPosition() {
    //   try {
    //     var res = await feathersClientTiger.service("person").find({});
    //     alert(JSON.stringify(res.data))
    //     res.data.forEach(element => {
    //       this.itemsListPosition.push(element.FullNameT);
    //     });
    //   } catch (error) {
    //     console.log(error);
    //     alert("ไม่สามารถขอข้อมูลจาก server ได้");
    //   }
    // },
    async getData(date,person) {
     
      const q = {};
      var x = date.substr(0, 4);
      var y = date.substr(5, 2);
      q.month = y;
      q.year = x;
      q.person = person;
      //Render Calendar
     var Fdate = new Date(x, y - 1, 1, 0, 0, 0, 0);
      // alert(Fdate);
      var x1 = startOfMonth(Fdate);
      var y1 = endOfMonth(Fdate);

      this.startCalendar = format(x1, "yyyy-MM-dd");
      this.endCalendar = format(y1, "yyyy-MM-dd");
      try {
        var res = await feathersClientTiger
          .service("schedulebyperson")
          .find({ query: q });
        this.CaledarData = res[0].calendarsData;
      } catch (error) {
        console.log(error);
        // this.$toast.error("ไม่สามารถติดต่อ server ได้");
      }
      this.events = this.CaledarData;
    },
    Gotolistflight(date) {
      this.today = date.date;
      this.$emit("DataCalender", date.date);
    }
  }
};
</script> 
<style  scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 20px;
  color: #ffffff;
  width: 100%;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  text-align: center;
  font-weight: 500;
  margin-top: 3px;
}
</style>